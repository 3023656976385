.videoRow{
    display: flex;
    margin-bottom: 1.5em;
    max-width: 700px;
}

.videoRow__image{
    object-fit: contain;
    width: 246px;
    height: 138px;
}

.videoRow__text{
    margin-left: 1em;
}

.videoRow__description{
    margin-top: 1.25em;
    font-size: 0.8rem;
    color: #606060;
}

.videoRow__subsNumber{
    font-weight: bolder;
    color: rgb(84, 157, 211);
}

.videoRow__subs{
    padding: 2px;
    border-radius: 5px;
    background-color: gray;
}

@media screen and (max-width: 786px) {
    .videoRow{
        font-size: 10px;
    }
    
    .videoRow__image{
        width: 200px;
        height: 100px;
    }

    .videoRow__description{
        margin-top: .7em;
    }
}