.sidebarRow{
    display: flex;
    align-items: center;
    padding: 0.625em 1.25em;
}

.sidebarRow__icon{
    color: #606060;
    font-size: large !important;
}

.sidebarRow__title{
    flex: 1;
    margin-left: 20px;
    font-size: 0.75em;
    font-weight: 500;
}

.sidebarRow.selected{
    background-color: lightgray;
}

.sidebarRow.selected .sidebarRow__icon{
    color: #f00;
}

.sidebarRow.selected .sidebarRow__title{
    font-weight: bold;
}

.sidebarRow:hover{
    background-color: lightgray;
    cursor: pointer;
}

.sidebarRow:hover > .sidebarRow__icon{
    color: #f00;
}
.sidebarRow:hover > .sidebarRow__title{
    font-weight: bold;
}