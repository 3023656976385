.videoCard{
    width: 270px;
    margin-bottom: 2.5em;
}

.videoCard__thumbnail{
    height: 140px;
    width: 250px;
}

.videoCard__info{
    display: flex;
    margin-top: 0.625em;
    padding-right: 1.875em;
}

.videoCard__text{
    margin-left: 1em;
}

.videCard__text > h4{
    font-size: 14px;
    margin-bottom: 5px;
}

.videoCard__text > p{
    font-size: 14px;
    color: gray;
}

.videoCard__avatar{
    height: 30px !important;
    width: 30px !important;
}