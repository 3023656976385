.channelRow{
    display: flex;
    align-items: center;
    width: 70%;
    max-width: 550px;
}

.channelRow__text{
    display: flex;
    flex-direction: column;
}

.channelRow__text > p {
    color: #606060;
    font-size: small !important;
}

.channelRow__logo{
    height: 120px !important;
    width: 120px !important;
    margin: 10px 60px;
}

.channelRow__text > h4{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 786px){

    .channelRow{
        width: 100%;
    }
    .channelRow__logo{
        height: 80px !important;
        width: 80px !important;
        margin: 5px 30px;
    }
}
